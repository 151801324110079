<template>
  <Header />
  <div class="row page-container q-mt-xl">
    <div class="col-md-6 profile-view">
      <div class="flex justify-between">
        <h6 class="q-mt-xs q-mb-xs q-ml-md text-weight-bold text-left">
          {{ $t("Profile.my-profile") }}
        </h6>
        <LogOut />
      </div>
      <!-- <CircleInfo /> -->
      <PersonalData />
      <PersonalAddress />
      <Privacidad />
      <Language />
    </div>
  </div>
  <Footer />
</template>

<script>
import { defineAsyncComponent } from "vue";

const Header = defineAsyncComponent(() =>
  import("../../shared/components/Header.vue")
);

const Footer = defineAsyncComponent(() =>
  import("../../shared/components/Footer.vue")
);

const PersonalData = defineAsyncComponent(() =>
  import("../components/profile/PersonalData.vue")
);

const PersonalAddress = defineAsyncComponent(() =>
  import("../components/profile/PersonalAddress.vue")
);

const Privacidad = defineAsyncComponent(() =>
  import("../components/profile/Privacidad.vue")
);

const Language = defineAsyncComponent(() =>
  import("../components/profile/Settings.vue")
);

const LogOut = defineAsyncComponent(() =>
  import("../components/profile/LogOut.vue")
);

// const CircleInfo = defineAsyncComponent(() =>
//   import("../components/profile/CircleInfo.vue")
// );

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    PersonalData,
    PersonalAddress,
    Privacidad,
    Language,
    LogOut,
    // CircleInfo,
  },
  
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .profile-view {
    width: 100%;
  }
}

</style>
